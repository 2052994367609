import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'technologies',
    templateUrl: './technologies.component.html',
    styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent {
    backgroundImage = '../../../../assets/img/NotActive.png';
    selectedTitle = '';

    constructor(private viewportScroller: ViewportScroller) { }

    changeBackground(image: string, title: string) {
        this.backgroundImage = image;
        this.selectedTitle = title;
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }
}
