<div id="technologies" class="cta-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="technologies-title">Technologies</h2>
            <p class="technology-subtitle">From front-end development to back-end architecture, we excel in a wide array of technologies and frameworks that empower you to build modern, scalable and lasting solutions. We stay ahead of the curve, constantly exploring emerging technologies and industry best practices.</p>
        </div>
        <div class="cta-content">
            <div class="technology-content-wrapper">
                <div class="technology-titles-wrapper">
                    <div>
                        <h4 class="technology-title technology-title-fe" (click)="changeBackground('../../../../assets/img/FrontEnd.png', 'fe')" [class.selected-technology]="selectedTitle === 'fe'">Frontend</h4>
                    </div>
                    <div>
                        <h4 class="technology-title technology-title-be"(click)="changeBackground('../../../../assets/img/BackEnd.png', 'be')" [class.selected-technology]="selectedTitle === 'be'">Backend</h4>
                    </div>
                    <div>
                        <h4 class="technology-title technology-title-devops"(click)="changeBackground('../../../../assets/img/DevOps.png', 'devops')" [class.selected-technology]="selectedTitle === 'devops'">DevOps</h4>
                    </div>
                    <div>
                        <h4 class="technology-title technology-title-qa"(click)="changeBackground('../../../../assets/img/Quality.png', 'qa')" [class.selected-technology]="selectedTitle === 'qa'">Quality</h4>
                    </div>
                </div>

                <img class="technologies-stack" src="{{backgroundImage}}"/>
            </div>
        </div>
    </div>
</div>
