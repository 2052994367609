import { Injectable } from '@angular/core';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

@Injectable({
    providedIn: 'root'
})
export class MailService {
    firebaseConfig = {
        apiKey: "AIzaSyB7AaxQorZiYZS_lnMJivu7tjzKNyXW0fk",
        authDomain: "purpleturtle-e9199.firebaseapp.com",
        projectId: "purpleturtle-e9199",
        storageBucket: "purpleturtle-e9199.appspot.com",
        messagingSenderId: "157569002062",
        appId: "1:157569002062:web:77f779b86be27f4990b11f",
        measurementId: "G-R3C5Z0YK7F"
    };

    // Initialize Firebase
    app = initializeApp(this.firebaseConfig);
    analytics = getAnalytics(this.app);

    db = getFirestore(this.app);

    sendMail(name: string, email: string, subject: string) {
        try {
            addDoc(collection(this.db, "mail"), {
                to: ['office@purpleturtle.dev'],
                from: 'no-reply@purpleturtle.dev',
                message: {
                    subject: 'Site Contact Form Received',
                    html: `<div><b>Name:</b> ${name}</div>
                    <div><b>Email:</b> ${email}</div>
                    <div><b>Message:</b> ${subject}</div>`,
                }
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }
}
