import { Component, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    isPrivacyPolicyRoute: boolean;
    isSticky: boolean = false;
    classApplied = false;

    constructor(private viewportScroller: ViewportScroller,
        private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isPrivacyPolicyRoute = event.url === '/privacy-policy';
            }
        });
    }

    // Navbar Sticky
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}
