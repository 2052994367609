<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
    *ngIf="!isPrivacyPolicyRoute"
>
    <div class="container">
        <a *ngIf="!isSticky" class="navbar-brand" routerLink="/" (click)="onClick('home')">
            <img src="../../../../assets/images/logo.png" alt="logo" />
        </a>
        <a *ngIf="isSticky" class="navbar-brand" routerLink="/" (click)="onClick('home')">
            <img src="../../../../assets/images/sticky-logo.png" alt="logo" />
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('technologies')">technologies</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about-us')">about us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('testimonials')">testimonials</span></li>
                <li class="nav-item lets-talk-nav-title"><span class="nav-link" (click)="onClick('contactUs')">let's talk!</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('technologies')">technologies</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about-us')">about us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('testimonials')">testimonials</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contactUs')">let's talk!</span></li>
            </ul>
        </div>
    </div>
</nav>
