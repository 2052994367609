import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
    {path: '', component: LayoutComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: '**', component: LayoutComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
