<footer class="footer-area">
    <div class="container">
        <div class="footer-container">
            <div class="footer-content">
                <img class="footer-logo" src="../../../../assets/images/sticky-logo.png" alt="">
            </div>

            <div class="footer-links">
                <a (click)="onClick('home')">Home</a>
                <a (click)="onClick('services')">Services</a>
                <a (click)="onClick('technologies')">Technologies</a>
                <a (click)="onClick('about-us')">About us</a>
                <a (click)="onClick('testimonials')">Testimonials</a>
            </div>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
