<div id="contactUs" class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="contact-us-title">Contact us</h2>
            <p class="contact-us-subtitle">Tell us about your project and get a free consultation. We’ll contact your back within 24 hours.</p>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 form-input-wrapper">
                                <div class="form-group mb-3 position-relative">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control underlined-input" id="name" placeholder=" ">
                                    <label for="name" class="form-label">Full name</label>
                                    <div class="alert alert-danger" *ngIf="submitClicked && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 form-input-wrapper">
                                <div class="form-group mb-3 position-relative">
                                    <input required email ngModel name="email" type="email" #email="ngModel" class="form-control underlined-input" id="email" placeholder=" " pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$">
                                    <label for="email" class="form-label">Email</label>
                                    <div class="alert alert-danger" *ngIf="email.invalid && submitClicked">
                                        <div *ngIf="email.errors.required">Email is required.</div>
                                        <div *ngIf="email.errors.email">Email is not valid.</div>
                                      </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 form-input-wrapper">
                                <div class="form-group mb-3 position-relative">
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control underlined-input" id="subject" placeholder=" ">
                                    <label for="subject" class="form-label">Tell us about your project</label>
                                    <div class="alert alert-danger" *ngIf="submitClicked && !subject.valid">The subject is required.</div>
                                </div>
                            </div>
                            <div class="row mt-3 recaptcha-wrapper">
                                <div class="col-sm-4 mb-2 recaptcha">
                                    <re-captcha #captchaElem id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                                    <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                                        <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-8 mb-2 contact-us-checkboxes">
                                    <div class="form-check">
                                        <input type="checkbox" id="terms" name="terms" ngModel #terms="ngModel" class="form-check-input" required>
                                        <label for="terms" class="form-check-label">I agree to have Purple Turtle contact me via email, phone, messengers.</label>
                                        <div *ngIf="submitClicked && terms.invalid" class="invalid-feedback d-block">
                                            <div *ngIf="terms.errors?.['required']">You must agree.</div>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" id="privacy" name="privacy" ngModel #privacy="ngModel" class="form-check-input" required>
                                        <label for="privacy" class="form-check-label">I agree to have Purple Turtle provide my request information to Purple Turtle’s affiliated development center.</label>
                                        <div *ngIf="submitClicked && privacy.invalid" class="invalid-feedback d-block">
                                            <div *ngIf="privacy.errors?.['required']">You must agree.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary contact-us-btn" (click)="submit($event)">Contact us</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-success-wrapper" *ngIf="isFormSubmitted">
        <div class="container">
            <div class="contact-success-content">
                <div class="section-title">
                    <h2 class="contact-us-title">Contact us</h2>
                    <p class="contact-us-subtitle">WE’LL GET BACK TO YOU WITHIN 8 WORKING HOURS</p>
                </div>
                <div class="contact-success-card">
                    <i class="material-icons">&#xe158;</i>
                    <p class="contact-success-card-message">YOUR MESSAGE HAS BEEN SENT SUCCESSFULLY.</p>
                </div>
                <button class="contact-success-close-button" (click)="onClose()">
                    <span class="custom-close-icon"></span>
                </button>
            </div>
        </div>
    </div>
</div>
