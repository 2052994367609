<div id="services" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="our-services-title">Our Services</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="service-wrapper first-service-wrapper">
                        <div class="services-number-wrapper">
                            <h2 class="our-services-number-title" style="margin-right: 8px">01</h2>
                        </div>
                        <div class="section-title our-services-title-wrapper">
                            <h2>Team Extension</h2>
                            <p>Amplify your engineering capacity with unmatched expertise and leave behind the burdens of resource constraints and talent gaps that hinder progress. Expand your team with well-versed software development experts and quality engineers, who seamlessly integrate with your existing organization, workflow, and processes.</p>
                        </div>
                    </div>
                    <div class="service-wrapper">
                        <div class="services-number-wrapper">
                            <h2 class="our-services-number-title" style="margin-right: 8px">02</h2>
                        </div>
                        <div class="section-title our-services-title-wrapper">
                           <h2>End-to-end Software Development</h2>
                           <p>Transform your visionary concepts into fully functional, robust software solutions that propel your business forward. Leverage our extensive knowledge and experience to tackle your most demanding projects efficiently and benefit from accelerated delivery timelines and heightened flexibility without compromising on quality.</p>
                        </div>
                    </div>
                    <div class="service-wrapper">
                        <div class="services-number-wrapper">
                            <h2 class="our-services-number-title" style="margin-right: 8px">03</h2>
                        </div>
                        <div class="section-title our-services-title-wrapper">
                            <h2>Managed Services</h2>
                            <p>Boost your IT operations effortlessly with our managed services, allowing you to accurately predict and control IT-related expenses. Gain access to our extensive talent pool, who cover a diverse set of skills and technologies, benefit from the full range of expert-level IT services and ensure flawless application functionality.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
