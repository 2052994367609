import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

import {
    NgcCookieConsentService,
    NgcNoCookieLawEvent,
    NgcInitializingEvent,
    NgcInitializationErrorEvent,
    NgcStatusChangeEvent
} from 'ngx-cookieconsent';

import { Subscription } from 'rxjs';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializingSubscription!: Subscription;
    private initializedSubscription!: Subscription;
    private initializationErrorSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;

    constructor(private router: Router,
        private cookieService: NgcCookieConsentService) {
    }

    ngOnInit() {
        this.recallJsFuntions();
        // subscribe to cookieconsent observables to react to main events
        this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(
            () => {
                // you can use this.cookieService.getConfig() to do stuff...
            });

        this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
            () => {
                // you can use this.cookieService.getConfig() to do stuff...
            });

        this.initializingSubscription = this.cookieService.initializing$.subscribe(
            (event: NgcInitializingEvent) => {
                // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
                console.log(`initializing: ${JSON.stringify(event)}`);
            });

        this.initializedSubscription = this.cookieService.initialized$.subscribe(
            () => {
                // the cookieconsent has been successfully initialized.
                // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
                console.log(`initialized: ${JSON.stringify(event)}`);
            });

        this.initializationErrorSubscription = this.cookieService.initializationError$.subscribe(
            (event: NgcInitializationErrorEvent) => {
                // the cookieconsent has failed to initialize...
                console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
            });

        this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                // you can use this.cookieService.getConfig() to do stuff...
            });

        this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
            () => {
                // you can use this.cookieService.getConfig() to do stuff...
            });

        this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
                // you can use this.cookieService.getConfig() to do stuff...
            });
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializingSubscription.unsubscribe();
        this.initializedSubscription.unsubscribe();
        this.initializationErrorSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }

}
