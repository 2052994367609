<div id="testimonials" class="testimonials-wrapper container">
    <img class="testimonial-img" src="../../../../assets/img/testimonials.png" alt="Testimonials image">
    <div class="testimonials">
      <input type="radio" name="testimonial" id="input-testimonial1" checked>
      <input type="radio" name="testimonial" id="input-testimonial2">
      <input type="radio" name="testimonial" id="input-testimonial3">
      <div class="testimonials-inner">
        <div class="testimonial">
          <div class="testimonial-photo">
            <div class="photo-background"></div>
            <div class="photo-author"></div>
          </div>
          <div class="testimonial-text">
            <p>Facing a tight timeframe to launch a critical functionality, we turned to Purple Turtle for support. They were quick in their response and onboarding of skilled frontend developers who bolstered our capacity. With their assistance, we met our deadlines and successfully rolled out the new feature.</p>
          </div>
          <div class="testimonial-author">Milen Manev</div>
          <div class="testimonial-author">CEO of Next Solutions</div>
        </div>
        <div class="testimonial">
          <div class="testimonial-photo">
            <div class="photo-background"></div>
            <div class="photo-author"></div>
          </div>
          <div class="testimonial-text">
            <p>Purple Turtle's professionals integrated so seamlessly with our team, it felt like they were full-time employees. They were quick to grasp the project objectives and began contributing valuable insights already during the first sprint.</p>
          </div>
          <div class="testimonial-author">Christian Makedonsky </div>
          <div class="testimonial-author">CEO of ITIDO Limited</div>
        </div>
        <div class="testimonial">
          <div class="testimonial-photo">
            <div class="photo-background"></div>
            <div class="photo-author"></div>
          </div>
          <div class="testimonial-text">
            <p>Purple Turtle impressed us with a systematic approach and effective, direct communication. Their team contributed to identified and fixed a performance issue of our system, leading to a significant improvement in loading speed and higher conversion rate.</p>
          </div>
          <div class="testimonial-author">Jure Romic</div>
          <div class="testimonial-author">CPO at HAPPYCAR</div>
        </div>
      </div>
      <div class="testimonials-bullets">
        <label for="input-testimonial1">
          <div class="bullet">
            <div>
              <span></span>
            </div>
          </div>
        </label>
        <label for="input-testimonial2">
          <div class="bullet">
            <div>
              <span></span>
            </div>
          </div>
        </label>
        <label for="input-testimonial3">
          <div class="bullet">
            <div>
              <span></span>
            </div>
          </div>
        </label>
      </div>
    </div>
</div>
