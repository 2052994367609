<section id="about-us" class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="about-us-title">Who we are</h2>
            <p class="about-us-subtitle">Purple Turtle is a software development company, that helps businesses navigate through the complex terrains of digital transformation by offering world-class engineering talent. We enable fast and efficient scaling of workforce capacity and are committed to delivering reliable software solutions that align with your specific needs.</p>
        </div>
        <div class="about-us-stats-wrapper row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <h3>9.6</h3>
                    <p>Net Promoter Score</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <h3>13+</h3>
                    <p>Years of Experience</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <h3>32</h3>
                    <p>Software Engineers</p>
                </div>
            </div>
        </div>
    </div>
</section>
