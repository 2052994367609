import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { NgForm } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    submitClicked = false;
    token: string | undefined;
    isFormSubmitted = false;
    contactForm: NgForm | undefined;

    constructor(private viewportScroller: ViewportScroller,
        private mailService: MailService) {
        this.token = undefined;
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    submit(form: NgForm) {
        this.contactForm = form;
        this.submitClicked = true;

        if (form.valid) {
            this.mailService.sendMail(form.value.name, form.value.email, form.value.subject);
            this.isFormSubmitted = true;
        }

        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }

            return;
        }
    }

    onClose(): void {
        this.isFormSubmitted = false;
        this.submitClicked = false;

        if (this.contactForm) {
            this.contactForm.resetForm();
        }

        this.viewportScroller.scrollToPosition([0, 0]);
    }

}
