<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-6 col-sm-2 partner-logo">
                <div class="funfact">
                    <img src="../../../../assets/img/vereign.png" alt="Vereign Logo">
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6 col-sm-2 partner-logo">
                <div class="funfact">
                    <img src="../../../../assets/img/Bosch_logo.png" alt="Bosch Logo">
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6 col-sm-2 partner-logo">
                <div class="funfact">
                    <img src="../../../../assets/img/buhler.png" alt="Buhler Logo">
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6 col-sm-2 partner-logo">
                <div class="funfact">
                    <img src="../../../../assets/img/othis.png" alt="Othis Logo">

                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6 col-sm-2 partner-logo">
                <div class="funfact">
                    <img src="../../../../assets/img/eps.png" alt="EPS Logo">

                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6 col-sm-2 partner-logo">
                <div class="funfact">
                    <img src="../../../../assets/img/happycar.png" alt="HappyCar Logo">

                </div>
            </div>
        </div>
    </div>
</section>
